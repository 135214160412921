/** @jsx jsx */

import { Link } from 'gatsby'
import { useEffect, useState } from 'react'
import { jsx } from 'theme-ui'

import Footer from '../components/footer'
import Halo from '../components/halo'
import Header from '../components/header'

const NotFound = () => {
  return (
    <div sx={{ display: `grid`, fontFamily: `body`, gap: 3 }}>
      <Halo title="Page not found" />
      <Header />

      <main sx={{ py: 5, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
        <div sx={{ maxWidth: 800, mx: `auto` }}>
          <h1
            sx={{
              my: 0,
              fontWeight: `600`,
              fontSize: [`2rem`, `2.5rem`, `3rem`],
              letterSpacing: `-0.02em`,
            }}
          >
            Page not found
          </h1>

          <p sx={{ mt: 2, mb: 4, fontSize: [`1rem`, `1.25rem`, `1.5rem`] }}>
            Go{' '}
            <Link
              sx={{
                color: `action`,
                textDecoration: `none`,

                ':hover, :focus': {
                  textDecoration: `underline`,
                },
              }}
              to="/"
            >
              home
            </Link>
            .
          </p>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default NotFound
